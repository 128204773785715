const Style = ({ attributes, clientId }) => {
	const { background, padding, pageSize, pageColor, prevNextSize, prevNextColor } = attributes;

	return <>
		<style dangerouslySetInnerHTML={{
			__html: `
			#bicbCarousel-${clientId}{
				${background?.styles || 'background-color: #0000;'}
				padding: ${padding?.styles || '10px 10px'};
			}
			#bicbCarousel-${clientId} .bicbCarousel .carousel{
				height: 250px;
			}
			#bicbCarousel-${clientId} .bicbCarousel .swiper-pagination .swiper-pagination-bullet{
				background: ${pageColor};
				width: ${pageSize};
				height: ${pageSize};
			}
			#bicbCarousel-${clientId} .bicbCarousel .swiper-button-prev:after, #bicbCarousel-${clientId} .bicbCarousel .swiper-button-next:after {
				font-size: ${prevNextSize};
			}
			#bicbCarousel-${clientId} .bicbCarousel .swiper-button-prev, #bicbCarousel-${clientId} .bicbCarousel .swiper-button-next{ color: ${prevNextColor}; }
			`.replace(/\s+/g, ' ')
		}} />
	</>
}
export default Style;