import { render } from '@wordpress/element';

import './css/swiper.min.css'; // Swiper CSS
import './style.scss';
import Style from './Style';
import Carousel from './Carousel';
import carouselOpt from './carouselOpt';

// Carousel Block
document.addEventListener('DOMContentLoaded', () => {
	const allCarousel = document.querySelectorAll('.wp-block-bicb-carousel');
	allCarousel.forEach(carousel => {
		const attributes = JSON.parse(carousel.dataset.attributes);

		render(<>
			<Style attributes={attributes} clientId={attributes.cId} />

			<Carousel attributes={attributes} clientId={attributes.cId} />
		</>, carousel);

		// Initialize Slider
		new Swiper(`#${carousel.id} .bicbCarousel`, carouselOpt(attributes, 0));

		carousel?.removeAttribute('data-attributes');
	});
});