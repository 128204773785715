const Carousel = ({ attributes }) => {
	const { carousels, isPage, isPrevNext } = attributes;

	return <div className='bicbCarousel'>
		<div className='swiper-wrapper'>
			{carousels?.map((carousel, index) => {
				const { image } = carousel;

				return <figure key={index} className={`carousel carousel-${index} swiper-slide`}>
					<img className={image?.id ? `wp-image-${image?.id}` : null} src={image?.url} alt={image?.alt || image?.title} />
				</figure>
			})}
		</div>

		{isPage && <div className='swiper-pagination'></div>}

		{isPrevNext && <><div className='swiper-button-prev'></div><div className='swiper-button-next'></div></>}
	</div>
};
export default Carousel;